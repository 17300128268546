<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section-first-page-hero"></section>

    <section class="section section-default section-text">
      <div class="container m-container-only-s">
        <h1>{{ $t("pages.shop") }}</h1>

        <h2>{{ $t("navigation.soon") }}</h2>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Shop",
  title() {
    return this.$t("pages.shop");
  }
};
</script>

<style scoped></style>
